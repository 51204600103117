import { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, ReactNode } from 'react';
import styled from '@emotion/styled';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: ReactNode;
  name: string;
  label: string;
  disable?: boolean;
  ref?: string;
}
const CheckboxForm: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ name, label, ...otherProps }, ref) => {
  return (
    <InputWrapper className='input-item'>
      <input type='checkbox' id={name} name={name} {...otherProps} ref={ref} />
      <label className='label-form' htmlFor={name}>
        {label}
      </label>
    </InputWrapper>
  );
};
const InputWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  /* padding: 5px; */
  /* margin-bottom: 30px; */
  input:disabled {
    cursor: no-drop;
    background-color: rgba(239, 239, 239, 0.3) !important;
    border-color: rgba(118, 118, 118, 0.3) !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
  }

  .label-form {
    line-height: 1;
    padding: 0 0 0 5px;
    font-size: 16px;
    font-family: 'Prompt', sans-serif;
    color: rgb(0, 0, 0, 0.6);
    transition: all ease-in-out 0.2s;
  }

  .input-form {
    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    border: 1px solid rgb(0, 0, 0, 0.3);
    box-sizing: border-box;
    padding: 10px;
    outline: none;
    font-family: 'Prompt', sans-serif;
    color: #000;
    font-size: 16px;
    padding-left: 30px;

    &:hover {
      border: 1px solid var(--primary-color);
    }
    &:focus {
      &:invalid {
        border: 1px solid red;
        border-radius: 10px;
      }
    }
  }
  .input-form:focus ~ .label-form,
  .input-form:not(:placeholder-shown) ~ .label-form {
    transform: translateX(-30px) translateY(-35px);
    font-size: 12px;
  }
`;
export default forwardRef(CheckboxForm);
